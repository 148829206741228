<div
  style="max-height: 70vh; min-height: 50vh"
  class="overflow-y-auto overflow-x-hidden"
>
  <ngx-designs-overview
    [radioMode]="true"
    [selectedCreativeId]="selectedCreativeId"
    [showFolderOptions]="false"
    (selected)="selectCreative($event); selectOnClick && submitHandler()"
    [withoutBorders]="true"
    [withoutDivide]="true"
    [mode]="selectOnClick ? 'submit' : 'radio'"
  />
</div>
@if (!selectOnClick) {
  <div class="mt-3 flex flex-row-reverse px-3">
    <confect-button type="primary" (click)="submitHandler()">
      Select
    </confect-button>
  </div>
}
