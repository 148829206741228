<confect-modal #modal [disableScroll]="true">
  <div class="w-[70vw] h-[70vh] relative">
    <div
      class="absolute gap-x-0 top-0 bottom-12 overflow-y-scroll overflow-x-hidden"
    >
      <ngx-designs-overview
        [radioMode]="true"
        [selectedCreativeId]="selectedDesignId"
        [showFolderOptions]="false"
        (selected)="selectCreative($event)"
        [withoutBorders]="true"
      />
    </div>
    <div class="mt-3 p-4 flex flex-row-reverse px-3 fixed bottom-0 right-0">
      <confect-button type="primary" (click)="submitHandler()">
        Select
      </confect-button>
    </div>
  </div>
</confect-modal>
