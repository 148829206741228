import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "ngx-designs-overview-import-table",
  templateUrl: "./designs-overview-import-table.component.html",
  styleUrls: ["./designs-overview-import-table.component.scss"],
})
export class DesignsOverviewImportTableComponent
  implements OnInit, AfterViewInit
{
  tableColumns = ["preview", "name", "edit"];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  _creatives = [];
  _dataSource;

  @Input() set creatives(to) {
    this._creatives = to;
    this._dataSource = new MatTableDataSource<any>(to);
    this._dataSource.paginator = this.paginator;
  }

  @Output() creativeSelected = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this._dataSource.paginator = this.paginator;
  }

  rowClicked(creative) {
    this.creativeSelected.emit(creative);
  }
}
