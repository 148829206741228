import { Directive, TemplateRef } from "@angular/core";

@Directive({ selector: "[list-folder-item-tmp]" })
export class FolderItemListDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({ selector: "[grid-folder-item-tmp]" })
export class FolderItemGridDirective {
  constructor(public template: TemplateRef<any>) {}
}
