<mat-table [dataSource]="_dataSource" class="import-table">
  <!-- Preview -->
  <ng-container matColumnDef="preview">
    <mat-header-cell *matHeaderCellDef>Preview</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="py-2">
        <img class="max-h-16 max-w-16" [src]="element.thumbnail" />
      </div>
    </mat-cell>
  </ng-container>

  <!-- Name -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
  </ng-container>

  <!-- Btns -->
  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef />
    <mat-cell
      class="mat-display-right"
      *matCellDef="let element; let i = index"
      style="display: flex; justify-content: flex-end"
    >
      <ng-container>
        <!-- Select Btn -->
        <confect-button
          size="small"
          type="secondary"
          (click)="rowClicked(element)"
        >
          Import
        </confect-button>
      </ng-container>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="tableColumns" />
  <mat-row class="import-row" *matRowDef="let element; columns: tableColumns" />
</mat-table>

<mat-paginator showFirstLastButtons />
