import { Directive, TemplateRef } from "@angular/core";

@Directive({ selector: "[list-sub-folder-tmp]" })
export class FolderSubListDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({ selector: "[grid-sub-folder-tmp]" })
export class FolderSubGridDirective {
  constructor(public template: TemplateRef<any>) {}
}
