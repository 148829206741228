import { Directive, TemplateRef } from "@angular/core";

@Directive({ selector: "[folder-item-tmp]" })
export class FolderItemDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({ selector: "[sub-folder-tmp]" })
export class SubFolderDirective {
  constructor(public template: TemplateRef<any>) {}
}
