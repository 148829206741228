import {
  Component,
  OnInit,
  ContentChild,
  TemplateRef,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewChecked,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { FolderService } from "@core/api/folder.service";

import { contextMenuOption } from "@theme/@confect/components/context-menu/context-menu.component";
import { CModalWarningComponent } from "@theme/@confect/components/modal-warning/modal-warning.component";
import {
  CPagerComponent,
  PagerInfo,
} from "@theme/@confect/components/pager/pager.component";
import {
  ContextMenuService,
  ContextRef,
} from "@theme/@confect/services/confect-context-menu.service";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Observable, map } from "rxjs";

import { FolderItemDirective, SubFolderDirective } from "./folders.directive";

export interface TypeSettings {
  grid: boolean;
  display_type?: { item: string; particle: string };
  listHeader?: { name: string; size: number }[];
  type?: string;
  cols?: number;
  gap_x?: number;
  gap_y?: number;
  header?: boolean;
  noImage?: boolean;
  searchable?: boolean;
  hasFolders?: boolean;
}

export interface FolderInfo {
  name?: string;
  id: number;
}

export interface FolderContents<T extends FolderInfo> {
  name?: string;
  id?: number;
  items: T[];
  folders: FolderInfo[];
}

export interface ItemContextOptions extends contextMenuOption {
  prio: number;
  key: string;
}

export interface ItemDelegate<T extends FolderInfo> {
  loadFolder(folder?: FolderInfo, options?): Observable<FolderContents<T>>;
  renameItem(item: T): Observable<boolean>;
  deleteItem(item): Observable<boolean>;
  duplicateItem(item): Observable<number>;
  openItem(item);
  getItemImage(item);
  actionsActivated(action: string, item: T);
}

export interface FolderDelegate {
  moveToFolder(
    itemType: string,
    itemId: number,
    folderID: number | null,
  ): Observable<boolean>;
  moveFolder(folderType: string, folderID: number, targetID: number | null);
  addFolder(
    folderType: string,
    name: string,
    parent?: number,
  ): Observable<boolean>;
  deleteFolder(
    folderType: string,
    folderID: number,
    targetID: number,
    unpackOption?,
  ): Observable<boolean>;
  renameFolder(folderID: number, folderName: string): Observable<boolean>;
}

export class FolderDelegateBase implements FolderDelegate {
  constructor(protected folderService: FolderService) {}

  moveToFolder(
    itemType: string,
    itemId: number,
    folderId: number | null,
  ): Observable<boolean> {
    return this.folderService
      .moveFolderElement(folderId != null ? folderId : 0, itemId, itemType)
      .pipe(
        map((res) => {
          return res.success;
        }),
      );
  }
  addFolder(
    folderType: string,
    name: string,
    parent?: number,
  ): Observable<boolean> {
    return this.folderService.createFolder(name, folderType, parent).pipe(
      map((res) => {
        return res.id;
      }),
    );
  }
  renameFolder(folderID: number, folderName: string): Observable<boolean> {
    return this.folderService
      .updateFolder({
        id: folderID,
        name: folderName,
      })
      .pipe(
        map((res) => {
          return res.success;
        }),
      );
  }

  moveFolder(folderType: string, folderID: number, targetID: number) {
    return this.folderService
      .moveFolder(targetID != null ? targetID : 0, folderID, folderType)
      .pipe(
        map((res) => {
          return res.success;
        }),
      );
  }
  deleteFolder(
    folderType: string,
    folderID: number,
    targetID: number,
    unpackOption?: any,
  ): Observable<boolean> {
    return this.folderService
      .deleteFolder(folderID, targetID, folderType, unpackOption)
      .pipe(
        map((res) => {
          return res.success;
        }),
      );
  }
}

export interface FolderOptions {
  sortByOptions: any[];
}

@Component({
  selector: "ngx-folders",
  templateUrl: "./folders.component.html",
})
export class FoldersComponent<T extends FolderInfo>
  implements OnInit, AfterViewChecked
{
  @ContentChild(FolderItemDirective, { read: TemplateRef })
  folderItem: TemplateRef<any>;
  @ContentChild(SubFolderDirective, { read: TemplateRef })
  subFolder: TemplateRef<any>;

  @ViewChild("pager") pager: CPagerComponent<T>;

  // // we create an object that contains coordinates
  // menuTopLeftPosition = { x: "0", y: "0" };

  itemMenu: ContextRef;
  folderMenu: ContextRef;

  // reference to the MatMenuTrigger in the DOM
  @ViewChild("renameModal") renameModal: TemplateRef<any>;
  @ViewChild("moveModal") moveModal: TemplateRef<any>;
  @ViewChild("modalWarning") modalWarning: CModalWarningComponent;

  items: FolderContents<T>[] = [];
  folderOver;
  itemDragged;
  selectedFolder;
  orderBy = "name";
  test = false;
  loading = true;
  isEmpty = true;
  openingFolder = false;
  _settings: TypeSettings;
  page = 0;
  lastPage = 0;
  itemTotal: number;
  searchString = "";
  pagerInfo: PagerInfo<T>;
  start_folder: number;

  @Input() folderDelegate?: FolderDelegate = new FolderDelegateBase(
    this.folderService,
  );

  @Input() delegate?: ItemDelegate<T>;
  @Input() set settings(to: TypeSettings) {
    this._settings = {
      header: to.header ?? true,
      noImage: to.noImage ?? false,
      grid: to.grid ?? false,
      cols: to.cols ?? 5,
      gap_x: to.gap_x ?? 8,
      gap_y: to.gap_y ?? 8,
      display_type: to.display_type ?? {
        item: "Item",
        particle: "an",
      },
      listHeader: to.listHeader ?? [],
      type: to.type,
      searchable: to.searchable ?? false,
      hasFolders: to.hasFolders ?? true,
    };

    this.setPaginator();
  }

  @Input() options: FolderOptions | null = null;
  @Input() selectMode: boolean = false;
  @Input() mode: "radio" | "submit" = "radio";
  @Input() selectedItem = null;
  @Input() set extras(to) {
    if (to?.moreOptions != null) {
      const opts = to.moreOptions;
      this.itemOptions = this.itemOptions.concat(
        opts.map((opt) => {
          return {
            prio: opt.prio,
            icon: opt.icon,
            display: opt.display,
            function: (item) => {
              this.delegate?.actionsActivated(opt.action, item.content);
              this.reloadFolder();
            },
            hide:
              opt.hide != null
                ? (item) => {
                    return this.delegate?.actionsActivated(
                      opt.hide,
                      item.content,
                    );
                  }
                : () => {
                    return false;
                  },
            disable:
              opt.disable != null
                ? (item) => {
                    return this.delegate?.actionsActivated(
                      opt.disable,
                      item.content,
                    );
                  }
                : () => {
                    return false;
                  },
            disableMsg: opt.disableMsg,
          };
        }),
      );
    }
    if (to?.disableOptions != null) {
      this.itemOptions.map((opt) => {
        if (to.disableOptions[opt.key] != null) {
          opt.disable = (item) =>
            this.delegate?.actionsActivated(opt.key, item.content);
          opt.disableMsg = to.disableOptions[opt.key];
        }
        return opt;
      });
    }
    if (to?.hideOptions != null) {
      this.itemOptions.map((opt) => {
        if (to.hideOptions.includes(opt.key)) {
          opt.hide = (item) =>
            this.delegate?.actionsActivated(opt.key, item.content);
        }
        return opt;
      });
    }
    this.itemOptions.sort((a, b) => (a.prio > b.prio ? -1 : 1));
  }

  @Output() itemSelected = new EventEmitter<T>();

  constructor(
    private popupModalService: CPopupModalService,
    private changeDetector: ChangeDetectorRef,
    protected folderService: FolderService,
    private contextService: ContextMenuService,
    private route: ActivatedRoute,
  ) {
    this.start_folder = parseInt(
      this.route.snapshot.queryParamMap.get("folder_id"),
    );
  }

  ngOnInit(): void {
    this.delegate?.loadFolder(null).subscribe({
      next: (res) => {
        this.items = this.items.concat(res);
        if (res.items.length !== 0 || res.folders.length !== 0) {
          this.isEmpty = false;
        }
        if (!isNaN(this.start_folder)) {
          this.loadFromFolder(this.start_folder, () => {
            this.loading = false;
            this.setPaginator();
          });
          return;
        }
        this.loading = false;
        this.setPaginator();
      },
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  setPaginator() {
    if (this.items.length === 0 || this._settings == null) {
      return;
    }
    this.pagerInfo = {
      items: this.items[this.items.length - 1].items,
      searchKey: "name",
      perPage: 4 * this._settings.cols,
    };
  }

  reloadFolder(options = null, postFunc = () => {}) {
    if (this.items.length > 0) {
      const folder = this.items[this.items.length - 1];
      this.openFolder(
        { name: folder.name, id: folder.id },
        options,
        () => {
          this.items.pop();
        },
        postFunc,
      );
    } else {
      this.openFolder(null, options, () => {}, postFunc);
    }
  }

  loadFromFolder(folderID: number, postFunc = null) {
    this.folderService.getFolderTree(folderID).subscribe({
      next: (res) => {
        const newItems = res.map((item) => {
          return { folders: [], items: [], id: item.id, name: item.name };
        });

        this.items = this.items.concat(newItems);

        this.reloadFolder(null, postFunc);
      },
    });
  }

  backFolder() {
    if (this.items.length > 1) {
      const folder = this.items[this.items.length - 2];
      this.openFolder({ name: folder.name, id: folder.id }, null, () => {
        this.items.pop();
        this.items.pop();
      });
    } else {
      this.openFolder(null, null, () => {
        this.items.pop();
      });
    }
  }

  openFolder(
    folder: FolderInfo,
    options = null,
    preFunc: () => void = () => {},
    postFunc = () => {},
  ) {
    if (this.openingFolder) {
      return;
    }
    this.openingFolder = true;
    this.delegate
      ?.loadFolder(folder, {
        order: { orderBy: this.orderBy, order: null },
        ...options,
      })
      .subscribe({
        next: (res) => {
          preFunc();

          this.pager?.paginator.updateData(res.items);

          this.items = this.items.concat(res);

          if (
            res.items.length === 0 &&
            res.folders.length === 0 &&
            this.items.length === 1 &&
            this.searchString === ""
          ) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
          this.openingFolder = false;
          postFunc();
        },
      });
  }

  addFolder(folderType: string, name: string, id: number) {
    this.folderDelegate?.addFolder(folderType, name, id).subscribe({
      next: (res) => {
        this.popupModalService.success({
          title: "Folder Created",
          autoCloseTimeout: 1500,
        });
        this.reloadFolder();
      },
      error: (err) => {
        this.popupModalService.error({
          title: `${err.error.detail}`,
          autoCloseTimeout: 1500,
        });
      },
    });
  }

  deleteFolder(folderType: string, folder) {
    const parentID = this.items[this.items.length - 1]?.id ?? 0;
    const unpack = { option: "unpack" };
    this.folderDelegate
      ?.deleteFolder(folderType, folder.id, parentID, unpack)
      .subscribe({
        next: (res) => {
          this.popupModalService.success({
            title: "Folder Deleted",
            autoCloseTimeout: 1500,
          });
          this.reloadFolder();
        },
        error: (err) => {
          this.popupModalService.error({
            title: `${err.error.detail}`,
            autoCloseTimeout: 1500,
          });
        },
      });
  }

  renameFolder(folderID, folderName) {
    this.folderDelegate?.renameFolder(folderID, folderName).subscribe({
      next: (res) => {
        this.popupModalService.success({
          title: "Folder Renamed",
          autoCloseTimeout: 1500,
        });
        this.reloadFolder();
      },
      error: (err) => {
        this.popupModalService.error({
          title: `${err.error.detail}`,
          autoCloseTimeout: 1500,
        });
      },
    });
  }

  renameItem(item) {
    this.delegate?.renameItem(item).subscribe({
      next: (res) => {
        this.popupModalService.success({
          title: `${this._settings.display_type.item} Renamed`,
          autoCloseTimeout: 1500,
        });
        this.reloadFolder();
      },
      error: (err) => {
        this.popupModalService.error({
          title: `${err.error.detail}`,
          autoCloseTimeout: 1500,
        });
      },
    });
  }

  deleteItem(item) {
    this.delegate?.deleteItem(item).subscribe({
      next: (res) => {
        this.popupModalService.success({
          title: `${this._settings.display_type.item} Deleted`,
          autoCloseTimeout: 1500,
        });
        this.reloadFolder();
      },
      error: (err) => {
        this.popupModalService.error({
          title: `${err.error.detail}`,
          autoCloseTimeout: 1500,
        });
      },
    });
  }

  duplicateItem(item) {
    this.delegate?.duplicateItem(item).subscribe({
      next: (res) => {
        this.popupModalService.success({
          title: `${this._settings.display_type.item} Duplicated`,
          autoCloseTimeout: 1500,
        });
        this.reloadFolder();
      },
      error: (err) => {
        this.popupModalService.error({
          title: `${err.error.detail}`,
          autoCloseTimeout: 1500,
        });
      },
    });
  }

  openItem(item) {
    if (this.selectMode) {
      this.selectedItem = item;
      this.itemSelected.emit(item);
    } else {
      this.delegate?.openItem(item);
    }
  }

  moveItem(itemID, folderID, postFunc = () => {}) {
    this.folderDelegate
      ?.moveToFolder(this._settings.type, itemID, folderID)
      .subscribe({
        next: (res) => {
          this.reloadFolder(null, postFunc);
        },
      });
  }

  goToFolder(index: number) {
    if (this.openingFolder) {
      return;
    }
    const items = JSON.parse(JSON.stringify(this.items));
    this.items = items.slice(0, index + 1);
    this.reloadFolder();
  }

  enter(folder) {
    this.folderOver = folder;
  }

  exited() {
    delete this.folderOver;
  }

  drop() {
    if (this.folderOver != null && this.itemDragged != null) {
      const folder = this.folderOver;
      const item = this.itemDragged.content;
      const type = this.itemDragged.type;

      if (folder !== item) {
        let observable;
        const current = this.items[this.items.length - 1];
        if (type === "item") {
          observable = this.folderDelegate?.moveToFolder(
            this._settings.type,
            item.id,
            folder.id,
          );
          current.items = current.items.filter((it) => it["id"] != item.id);
        } else if (type === "folder") {
          observable = this.folderDelegate?.moveFolder(
            this._settings.type,
            item.id,
            folder.id,
          );
          current.folders = current.folders.filter((it) => it["id"] != item.id);
        }
        observable.subscribe({
          next: (res) => {
            this.reloadFolder();
            this.exited();
          },
        });
      }
    }
    delete this.itemDragged;
  }

  drag(item) {
    if (!this._settings.header) {
      return;
    }
    this.itemDragged = item;
  }

  rightClick(event, item) {
    if (this.selectMode) {
      return;
    }

    if (this.itemMenu != null) {
      this.itemMenu.close(event);
      this.itemMenu = null;
    }
    if (this.folderMenu != null) {
      this.folderMenu.close(event);
      this.folderMenu = null;
    }

    if (item.type === "folder") {
      this.folderMenu = this.contextService.open({
        options: this.folderOptions,
        event: event,
        item: item,
      });

      this.folderMenu.afterClose.subscribe({
        next: () => {
          this.folderMenu = null;
        },
      });

      return;
    }

    this.itemMenu = this.contextService.open({
      options: this.itemOptions,
      event: event,
      item: item,
    });

    this.itemMenu.afterClose.subscribe({
      next: () => {
        this.itemMenu = null;
      },
    });
  }

  resetModal() {
    delete this.selectedItem;
    delete this.selectedFolder;
  }
  goToItem = (item) => this.openItem(item);
  open = (folder) => {
    this.openFolder(folder);
    if (this.pager) {
      this.pager.paginator.CURRENT_PAGE = 1;
    }
  };
  back = () => {
    this.backFolder();
    if (this.pager) {
      this.pager.paginator.CURRENT_PAGE = 1;
    }
  };
  add = (id: number) => {
    this.popupModalService
      .template({ template: this.renameModal, showClose: false })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          this.resetModal();
        },
      });
    this.selectedItem = {
      type: "new folder",
      content: { name: "New Folder", id: id },
    };
  };
  move = (item) => {
    this.popupModalService
      .template({
        template: this.moveModal,
        showClose: false,
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          this.resetModal();
        },
      });
    this.selectedItem = item;
  };
  delete = (item) => {
    const title_item =
      item.type === "folder"
        ? "Folder"
        : (this._settings.display_type.item ?? "Item");
    const text_item =
      item.type === "folder"
        ? "a folder"
        : this._settings.display_type != null
          ? `${this._settings.display_type?.particle} ${this._settings.display_type?.item}`
          : "an item";
    this.popupModalService
      .warning({
        title: `Delete ${title_item}`,
        text: `You are about to delete ${text_item}. Are you sure this is what you want?`,
        confirmText: "Delete",
        cancelText: "Cancel",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.selectedItem.type === "item"
              ? this.deleteItem(this.selectedItem.content)
              : this.deleteFolder(
                  this._settings.type,
                  this.selectedItem.content,
                );
          }
        },
      });
    this.selectedItem = JSON.parse(JSON.stringify(item));
  };
  rename = (item) => {
    this.popupModalService
      .template({ template: this.renameModal, showClose: false })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          this.resetModal();
        },
      });
    this.selectedItem = JSON.parse(JSON.stringify(item));
  };
  duplicate = (item) => this.duplicateItem(item.content);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  folderOptions = [
    {
      icon: "edit",
      display: "Rename",
      function: this.rename,
      hide: (item) => {
        return false;
      },
      disable: (item) => {
        return false;
      },
    },
    {
      icon: "delete",
      display: "Delete",
      function: this.delete,
      hide: (item) => {
        return false;
      },
      disable: (item) => {
        return false;
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  itemOptions: ItemContextOptions[] = [
    {
      prio: 10,
      key: "rename",
      icon: "edit",
      display: "Rename",
      function: this.rename,
      hide: (item) => {
        return false;
      },
      disable: (item) => {
        return false;
      },
    },
    {
      prio: 5,
      key: "duplicate",
      icon: "copy",
      display: "Duplicate",
      function: this.duplicate,
      hide: (item) => {
        return false;
      },
      disable: (item) => {
        return false;
      },
    },
    {
      prio: -10,
      key: "delete",
      icon: "delete",
      display: "Delete",
      function: this.delete,
      hide: (item) => {
        return false;
      },
      disable: (item) => {
        return false;
      },
    },
    {
      prio: 3,
      icon: "folder",
      key: "move",
      display: "Move",
      function: this.move,
      hide: (item) => {
        return !this._settings.header;
      },
      disable: (item) => {
        return false;
      },
    },
  ];

  menu = (event, item) => this.rightClick(event, item);
}
