import { Injectable } from "@angular/core";

import { CreativeLayer } from "@core/models/creative.types";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Observable, Subject } from "rxjs";

import {
  CardItemInterface,
  CategoryInterface,
  CreateSharedElementInterface,
  ElementCategoryItem,
  ElementOverviewSection,
  SharedElementInterface,
  SideMenuStructureInterface,
  TempStructure,
} from "../api-models/shared-elements.models";
import { ApiService } from "./api.service";
import { DesignsService } from "./designs.service";

@Injectable({
  providedIn: "root",
})
export class SharedElementsService extends ApiService {
  public cardItems: CardItemInterface[] = [];

  private newElementCreated = new Subject<boolean>();
  public newElementCreated$ = this.newElementCreated.asObservable();

  public getElements(): Observable<SharedElementInterface[]> {
    return this.http.get<SharedElementInterface[]>(
      `${this.endpoint}/designs/elements/all`,
    );
  }

  getElementsByCategoryV2(
    category: string,
  ): Observable<SharedElementInterface[]> {
    return this.http.get<SharedElementInterface[]>(
      `${this.endpoint}/designs/elements/v2/shared?category=${category}`,
    );
  }

  public getElementsByCategory(category): Observable<SharedElementInterface[]> {
    return this.http.get<SharedElementInterface[]>(
      `${this.endpoint}/designs/elements/category?group=${category.group}&value=${category.value}`,
    );
  }

  public deleteElement(elementId: number): Observable<any> {
    return this.http.delete(`${this.endpoint}/designs/elements/${elementId}`);
  }

  public getUserElements(): Observable<SharedElementInterface[]> {
    return this.http.get<SharedElementInterface[]>(
      `${this.endpoint}/designs/elements/user`,
    );
  }

  public getUserElementsFolder(folderID = null): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}/designs/elements/user/by_folder${
        folderID != null ? `?ui_folder_id=${folderID}` : ""
      }`,
    );
  }

  getElementOverview(): Observable<ElementOverviewSection[]> {
    return this.http.get<ElementOverviewSection[]>(
      `${this.endpoint}/designs/elements/v2/overview`,
    );
  }

  public createElement(
    element: CreateSharedElementInterface,
  ): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${this.endpoint}/designs/elements`,
      element,
    );
  }

  public createPublicElement(
    element: CreateSharedElementInterface,
  ): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${this.endpoint}/designs/elements/v2/shared`,
      element,
    );
  }

  public markElementUsed(elementID: number): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/designs/elements/v2/${elementID}/mark_used`,
      null,
    );
  }

  public getElementCategories(): Observable<ElementCategoryItem[]> {
    return this.http.get<ElementCategoryItem[]>(
      `${this.endpoint}/designs/elements/v2/shared/categories`,
    );
  }

  public updateElement(
    element: SharedElementInterface,
    name: string,
  ): Observable<{ success: boolean }> {
    //This should in theory also be able to change categories and spec
    //but at the moment it is only implmented for rename
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/designs/elements/${element.id}`,
      { name: name, categories: element.categories, spec: element.spec },
    );
  }

  public getElement(elementId: number): Observable<SharedElementInterface> {
    return this.http.get<SharedElementInterface>(
      `${this.endpoint}/designs/elements/${elementId}`,
    );
  }

  sortByKey(array, key): any[] {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];

      if (typeof x == "string" && typeof y == "string") {
        return x.toLowerCase().localeCompare(y.toLowerCase());
      }
      return x - y;
    });
  }

  public createSideMenuStructure(
    sharedElements: SharedElementInterface[],
  ): SideMenuStructureInterface[] {
    return this.sortByKey(
      Object.values(
        sharedElements.reduce(
          (acc: TempStructure, { categories }: SharedElementInterface) =>
            categories.reduce(
              (categoryAcc: TempStructure, category: CategoryInterface) => {
                const { options = [] } = categoryAcc[category.group] || {};

                return {
                  ...categoryAcc,
                  [category.group]: {
                    name: category.group,
                    options: Array.from(new Set([...options, category.value])),
                  },
                };
              },
              acc,
            ),
          {},
        ),
      ),
      "name",
    );
  }

  public getCardItems(
    sharedElements: SharedElementInterface[],
  ): CardItemInterface[] {
    return sharedElements.reduce(
      (acc: CardItemInterface[], item): CardItemInterface[] => {
        acc.push({
          id: item.id,
          name: item.name,
          thumbnail: item.thumbnail,
          categories: item.categories.map(
            (category: CategoryInterface) => category.group,
          ),
          options: item.categories.map(
            (category: CategoryInterface) => category.value,
          ),
          spec: item.spec,
        });

        return acc;
      },
      [],
    );
  }

  public saveLayerGroup(
    layer: CreativeLayer,
    designService: DesignsService,
    popUpService: CPopupModalService,
  ) {
    const layerCopy: CreativeLayer = JSON.parse(JSON.stringify(layer));

    popUpService
      .input({
        title: "Save element for reuse",
        text: "By saving an element you can import and reuse it in other designs. Give your element a name.",
        type: "text",
        value: layerCopy.name,
        invalidText: "The element needs a name",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (!res) {
            return;
          }
          // Save
          designService.saveCreativeElement(res, layerCopy).subscribe({
            next: (res) => {
              this.newElementCreated.next(true);
              popUpService.success({
                title: "Saved!",
                autoCloseTimeout: 1500,
              });
            },
          });
        },
      });
  }
}
