import { ApplicationRef, Injectable } from "@angular/core";

import { AccountState } from "@core/api-models/settings.models";
import { SettingsService } from "@core/api/settings.service";

import { Observable, map } from "rxjs";

import { AccessService } from "./access.service";
import { DataBaseService } from "./base/data-base-service";
import { ProctorService } from "./proctor.service";

@Injectable({
  providedIn: "root",
})
export class AccountStateService extends DataBaseService<AccountState> {
  refresher(): Observable<AccountState> {
    return this.settingsService.getAccount().pipe(map((res) => res.state));
  }

  constructor(
    public appRef: ApplicationRef,
    public accessService: AccessService,
    public proctorService: ProctorService,
    public settingsService: SettingsService,
  ) {
    super(appRef, accessService, proctorService, false);
    this.init();
  }

  init(): void {
    this.getData(true);
  }
}
