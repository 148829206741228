import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { FolderInfo } from "@components/folders";

import { Observable } from "rxjs";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class FolderService extends ApiService {
  getFolders(folderType): Observable<any> {
    const params = { type: folderType };
    return this.http.get(this.baseURL + "/settings/folders", { params });
  }

  getFolderData(folderId): Observable<any> {
    const params = { folder: folderId };
    return this.http.get(this.endpoint + "/designs", { params });
  }

  createFolder(folderName, folderType, parentId): Observable<any> {
    const params = { parent: parentId, type: folderType, name: folderName };
    return this.http.post(this.endpoint + "/settings/folders", params);
  }

  updateFolder(folder): Observable<any> {
    if (folder.id == null) {
      return this.http.post(this.endpoint + "/settings/folders", folder);
    }
    return this.http.post(
      this.endpoint + "/settings/folders/" + folder.id,
      folder,
    );
  }

  deleteFolder(
    folderId,
    targetFolderId,
    elementModel,
    unpackOption?,
  ): Observable<any> {
    const options = { element_model: elementModel, ...unpackOption };
    if (targetFolderId != null) options["target_folder_id"] = targetFolderId;
    return this.http.delete(
      this.endpoint + "/settings/folders/" + folderId,
      this.params(options),
    );
  }

  moveFolderElement(folderId, elementId, elementModel): Observable<any> {
    const json = {
      to_folder_id: folderId,
      element_id: elementId,
      element_model: elementModel,
    };
    return this.http.post(
      this.endpoint + "/settings/folders/move-element",
      json,
    );
  }

  moveFolder(targetID, folderID, elementModel): Observable<any> {
    const json = {
      to_folder_id: targetID,
      element_id: folderID,
      element_model: elementModel,
    };
    return this.http.post(
      this.endpoint + "/settings/folders/move-folder",
      json,
    );
  }

  getFolderTree(folder_id: number): Observable<FolderInfo[]> {
    return this.http.get<FolderInfo[]>(
      this.endpoint + "/settings/folders/tree_info",
      {
        params: new HttpParams({
          fromObject: {
            folder_id: folder_id,
          },
        }),
      },
    );
  }
}
