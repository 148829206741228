import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";

import { FolderComponentModule } from "@components/folders";

import { ThemeModule } from "@theme/theme.module";

import { CreativeSelectorV2Component } from "./creative-selector-v2/creative-selector.component";
import { CreativeSelectorComponent } from "./creative-selector/creative-selector.component";
import { CreativesTemplatesComponent } from "./creatives-templates/creatives-templates.component";
import { DesignElementsOverviewComponent } from "./design-elements-overview/design-elements-overview.component";
import { DesignSelectModalComponent } from "./design-select-modal/design-select-modal.component";
import {
  DesignsOverviewImportComponent,
  DesignsOverviewImportTableComponent,
  DesignsOverviewComponent,
} from "./designs-overview";
import { MediaOverviewComponent } from "./media-overview/media-overview.component";

const COMPONENTS = [
  DesignsOverviewImportComponent,
  DesignsOverviewImportTableComponent,
  DesignsOverviewComponent,
  CreativeSelectorV2Component,
  CreativeSelectorComponent,
  DesignSelectModalComponent,
  CreativesTemplatesComponent,
  DesignElementsOverviewComponent,
  MediaOverviewComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    ThemeModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    FolderComponentModule,
  ],
  exports: [...COMPONENTS],
})
export class DesignsComponentModule {}
