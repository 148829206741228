@if (grid) {
  <div
    (contextmenu)="
      rightClicked.emit({
        event: $event,
        content: { content: folder, type: 'folder' },
      })
    "
    cdkDropList
    [cdkDropListData]="{ content: folder, type: 'folder' }"
    (mouseover)="enter.emit(folder)"
    (mouseout)="exited.emit()"
  >
    <div
      cdkDrag
      [cdkDragDisabled]="selectMode || backFolder"
      (cdkDragStarted)="drag.emit({ content: folder, type: 'folder' })"
      (cdkDragEnded)="drop.emit()"
      class="z-[1050]"
    >
      <div *cdkDragPlaceholder></div>
      <ng-template
        [ngTemplateOutlet]="subFolderGrid || defualtSubFolderGrid"
        [ngTemplateOutletContext]="{
          folder: folder,
          open: open,
        }"
      />
    </div>
  </div>
}
@if (!grid) {
  <tr
    cdkDrag
    [cdkDragDisabled]="backFolder"
    [cdkDragData]="{ content: folder, type: 'folder' }"
    (cdkDragStarted)="drag.emit({ content: folder, type: 'folder' })"
    (cdkDragEnded)="drop.emit()"
    (click)="clicked.emit({ name: folder.name, id: folder.id })"
    (mouseover)="enter.emit(folder)"
    (mouseout)="exited.emit()"
    (contextmenu)="
      rightClicked.emit({
        event: $event,
        content: { content: folder, type: 'folder' },
      })
    "
    class="bg-white group cursor-pointer z-[1050]"
  >
    <div *cdkDragPreview>
      <img
        [src]="'/assets/images/folder-images/foldersv2-02.png'"
        class="object-contain object-center w-32"
      />
    </div>
    <div *cdkDragPlaceholder class="h-20 bg-white"></div>
    <ng-template
      [ngTemplateOutlet]="subFolderList || defualtSubFolderList"
      [ngTemplateOutletContext]="{
        folder: folder,
        open: open,
      }"
    />
  </tr>
}

<ng-template #defualtSubFolderGrid let-folder="folder" let-open="open">
  <div
    (click)="open({ name: folder.name, id: folder.id })"
    class="flex flex-col group cursor-pointer aspect-square relative"
  >
    @if (!selectMode && !dragged && !backFolder) {
      <div
        class="absolute z-10 right-2 top-2 bg-white rounded shadow-md"
        (click)="
          $event.stopPropagation();
          rightClicked.emit({
            event: $event,
            content: { content: folder, type: 'folder' },
          })
        "
        [ngClass]="{
          block: contextOpen,
          'hidden group-hover:block': !contextOpen,
        }"
      >
        @if (!selectMode) {
          <confect-icon
            icon="more_vertical"
            class="text-2xl hover:text-slate-400 cursor-pointer"
          />
        }
      </div>
    }
    <div
      class="flex flex-col items-center justify-center relative h-full w-full aspect-square"
    >
      <img
        [src]="
          backFolder
            ? '/assets/images/folder-images/foldersv2-04.png'
            : '/assets/images/folder-images/foldersv2-02.png'
        "
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain object-center mx-auto flex-shrink-0 rounded"
        [ngClass]="{
          'opacity-100 group-hover:opacity-75': !selected || !dragged,
          'opacity-0': selected && dragged,
        }"
      />
      <img
        [src]="
          backFolder
            ? '/assets/images/folder-images/foldersv2-05.png'
            : '/assets/images/folder-images/foldersv2-03.png'
        "
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain object-center mx-auto flex-shrink-0 rounded"
        [ngClass]="{
          'opacity-100': selected && dragged,
          'opacity-0': !selected || !dragged,
        }"
      />
    </div>
    <div>
      <div class="text-sm font-medium text-gray-700 pl-1 pr-4">
        {{ folder.name }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defualtSubFolderList let-folder="folder" let-open="open">
  @if (!noImage) {
    <td class="relative h-24 flex items-center justify-center">
      <img
        [ngClass]="{
          'opacity-100 group-hover:opacity-75': !selected || !dragged,
          'opacity-0': selected && dragged,
        }"
        [src]="
          backFolder
            ? '/assets/images/folder-images/foldersv2-04.png'
            : '/assets/images/folder-images/foldersv2-02.png'
        "
        class="absolute top-1/2 -translate-y-1/2 object-contain object-center h-20 p-2"
      />
      <img
        [ngClass]="{
          'opacity-100': selected && dragged,
          'opacity-0': !selected || !dragged,
        }"
        [src]="
          backFolder
            ? '/assets/images/folder-images/foldersv2-05.png'
            : '/assets/images/folder-images/foldersv2-03.png'
        "
        class="absolute top-1/2 -translate-y-1/2 object-contain object-center h-20 p-2"
      />
    </td>
  }
  <td [ngClass]="{ 'h-16 flex items-center': noImage }">
    @if (noImage) {
      <div class="flex relative h-12 w-12 mr-3 items-center justify-center">
        <img
          [ngClass]="{
            'opacity-100 group-hover:opacity-75': !selected || !dragged,
            'opacity-0': selected && dragged,
          }"
          [src]="
            backFolder
              ? '/assets/images/folder-images/foldersv2-04.png'
              : '/assets/images/folder-images/foldersv2-02.png'
          "
          class="absolute object-contain object-center h-12"
        />
        <img
          [ngClass]="{
            'opacity-100': selected && dragged,
            'opacity-0': !selected || !dragged,
          }"
          [src]="
            backFolder
              ? '/assets/images/folder-images/foldersv2-05.png'
              : '/assets/images/folder-images/foldersv2-03.png'
          "
          class="absolute object-contain object-center h-12"
        />
      </div>
    }
    <div class="text-sm font-medium text-gray-700">
      {{ folder.name }}
    </div>
  </td>
  @for (_ of [].constructor(headerLength - 1); track _) {
    <td></td>
  }
  <td class="content-center">
    @if (!selectMode && !dragged && !backFolder) {
      <div
        class="rounded shadow-md w-8 text-center"
        (click)="
          $event.stopPropagation();
          rightClicked.emit({
            event: $event,
            content: { content: folder, type: 'folder' },
          })
        "
        [ngClass]="{
          block: contextOpen,
          'hidden group-hover:block': !contextOpen,
        }"
      >
        <confect-icon
          icon="more_vertical"
          class="text-2xl hover:text-slate-400 cursor-pointer"
        />
      </div>
    }
  </td>
</ng-template>
