import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ThemeModule } from "@theme/theme.module";

import { FoldersComponent } from "./";
import { FoldersFolderComponent } from "./folders/folders-folder/folders-folder.component";
import {
  FolderSubGridDirective,
  FolderSubListDirective,
} from "./folders/folders-folder/folders-folder.directive";
import { FoldersItemComponent } from "./folders/folders-item/folders-item.component";
import {
  FolderItemGridDirective,
  FolderItemListDirective,
} from "./folders/folders-item/folders-item.directive";
import {
  FolderItemDirective,
  SubFolderDirective,
} from "./folders/folders.directive";

const COMPONENTS = [
  FoldersComponent,
  FolderItemGridDirective,
  FolderItemListDirective,
  FolderSubGridDirective,
  FolderSubListDirective,
  FolderItemDirective,
  SubFolderDirective,
  FoldersItemComponent,
  FoldersFolderComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, RouterModule, ThemeModule, DragDropModule],
  exports: [...COMPONENTS],
})
export class FolderComponentModule {}
