@if (!loading && !isEmpty) {
  <div>
    @if (_settings.header) {
      <div class="flex items-center justify-between mb-2 border-b h-16">
        @if (_settings.grid || _settings.searchable) {
          <div class="mb-2.5 flex items-center justify-center">
            <confect-input
              [(ngModel)]="searchString"
              (ngModelChange)="
                _settings.hasFolders &&
                  pager?.paginator?.changeQuery(searchString);
                reloadFolder()
              "
              [placeholder]="
                items.length > 1 || items[items.length - 1].folders.length > 0
                  ? 'Search in folder...'
                  : 'Search for ' +
                    _settings.display_type.item.toLowerCase() +
                    '...'
              "
            />
          </div>
        } @else {
          <div class="mb-2.5 flex flex-grow items-center space-x-2">
            @for (item of items; track item.id; let i = $index) {
              <span
                class="text-lg"
                [ngClass]="{
                  ' hover:text-primary-500 cursor-pointer text-gray-500':
                    i !== items.length - 1,
                  'cursor-default text-gray-400': i === items.length - 1,
                }"
                (click)="goToFolder(i)"
              >
                @if (i > 0) {
                  {{ item.name }}
                } @else {
                  <confect-icon
                    [iconClass]="
                      items.length === 1
                        ? 'text-2xl text-gray-300'
                        : 'text-2xl text-gray-400 hover:text-primary-500'
                    "
                    icon="home"
                  />
                }
              </span>
              @if (items.length !== 1 && i !== items.length - 1) {
                <confect-icon
                  icon="caret_right"
                  iconClass="text-2xl text-gray-300"
                />
              }
            }
          </div>
        }
        <div class="mb-2.5 flex items-center justify-center space-x-2">
          @if (options?.sortByOptions != null) {
            <div class="flex items-center justify-center">
              <confect-select
                [options]="options.sortByOptions"
                [searchable]="false"
                [clearable]="false"
                size="extra-height"
                (change)="reloadFolder()"
                [(ngModel)]="orderBy"
                preLabel="Sort by"
                class="block w-56 h-full"
              />
            </div>
          }
          @if (!selectMode) {
            <div class="flex items-center justify-center">
              <confect-button
                type="white"
                (click)="add(items[items.length - 1]?.id)"
              >
                <confect-icon [icon]="'folder_add'" iconClass="mr-1 text-xl" />
                New Folder
              </confect-button>
            </div>
          }
        </div>
      </div>
      @if (
        (_settings.grid || (_settings.searchable && _settings.hasFolders)) &&
        (items[items.length - 1].folders.length !== 0 || items.length > 1)
      ) {
        <div class="mb-2.5 flex flex-grow items-center space-x-2">
          @for (item of items; track item.id; let i = $index) {
            <span
              class="text-lg"
              [ngClass]="{
                ' hover:text-primary-500 cursor-pointer text-gray-500':
                  i !== items.length - 1,
                'cursor-default text-gray-400': i === items.length - 1,
              }"
              (click)="goToFolder(i)"
            >
              @if (i > 0) {
                {{ item.name }}
              } @else {
                <confect-icon
                  [iconClass]="
                    items.length === 1
                      ? 'text-2xl text-gray-300'
                      : 'text-2xl text-gray-400 hover:text-primary-500'
                  "
                  icon="home"
                />
              }
            </span>
            @if (items.length !== 1 && i !== items.length - 1) {
              <confect-icon
                icon="caret_right"
                iconClass="text-2xl text-gray-300"
              />
            }
          }
        </div>
      }
    }
    @if (_settings.grid) {
      <div cdkDropListGroup>
        @if (items.length > 0) {
          @if (
            _settings.header &&
            (items[items.length - 1].folders.length !== 0 || items.length !== 1)
          ) {
            <div
              class="grid mt-8 justify-center"
              [ngClass]="{
                'grid-cols-7 gap-x-5 gap-y-5': _settings.grid,
                'divide-y divide-y-slate-400': !_settings.grid,
                'border-b pb-8': _settings.header,
              }"
            >
              @if (items.length > 1) {
                <ngx-folders-folder
                  [backFolder]="true"
                  [folder]="{ id: items[items.length - 2]?.id, name: 'Back' }"
                  [selectMode]="selectMode"
                  [dragged]="itemDragged != null"
                  [selected]="items[items.length - 2]?.id === folderOver?.id"
                  [grid]="_settings.grid"
                  [headerLength]="_settings.listHeader?.length"
                  (clicked)="back()"
                  (exited)="exited()"
                  (enter)="enter($event)"
                >
                  <ng-template
                    *ngIf="subFolder != null"
                    grid-sub-folder-tmp
                    let-folder="folder"
                    let-open="open"
                  >
                    <ng-template
                      [ngTemplateOutlet]="subFolder"
                      [ngTemplateOutletContext]="{ folder: folder, open: open }"
                    />
                  </ng-template>
                </ngx-folders-folder>
              }
              @for (
                folder of items[items.length - 1].folders;
                track folder.id
              ) {
                <ngx-folders-folder
                  [folder]="folder"
                  [selectMode]="selectMode"
                  [dragged]="itemDragged != null"
                  [selected]="folder.id === folderOver?.id"
                  [grid]="_settings.grid"
                  [headerLength]="_settings.listHeader?.length"
                  [contextOpen]="
                    folderMenu != null &&
                    folder.id === folderMenu.options.item.content.id &&
                    folderMenu.options.item.type === 'folder'
                  "
                  (clicked)="open($event)"
                  (drag)="drag($event)"
                  (rightClicked)="rightClick($event.event, $event.content)"
                  (drop)="drop()"
                  (exited)="exited()"
                  (enter)="enter($event)"
                >
                  <ng-template
                    *ngIf="subFolder != null"
                    grid-sub-folder-tmp
                    let-folder="folder"
                    let-open="open"
                  >
                    <ng-template
                      [ngTemplateOutlet]="subFolder"
                      [ngTemplateOutletContext]="{ folder: folder, open: open }"
                    />
                  </ng-template>
                </ngx-folders-folder>
              }
            </div>
          }
          <div
            class="grid mt-8 mb-8 justify-center"
            [ngClass]="{
              'grid-cols-1': _settings.cols === 1 || !_settings.grid,
              'grid-cols-2': _settings.cols === 2 && _settings.grid,
              'grid-cols-3': _settings.cols === 3 && _settings.grid,
              'grid-cols-4': _settings.cols === 4 && _settings.grid,
              'grid-cols-5': _settings.cols === 5 && _settings.grid,
              'grid-cols-6': _settings.cols === 6 && _settings.grid,
              'grid-cols-7': _settings.cols === 7 && _settings.grid,
              'grid-cols-8': _settings.cols === 8 && _settings.grid,
              'gap-x-0': _settings.gap_x === 0 && _settings.grid,
              'gap-x-2': _settings.gap_x === 2 && _settings.grid,
              'gap-x-4': _settings.gap_x === 4 && _settings.grid,
              'gap-x-6': _settings.gap_x === 6 && _settings.grid,
              'gap-x-8': _settings.gap_x === 8 && _settings.grid,
              'gap-x-10': _settings.gap_x === 10 && _settings.grid,
              'gap-x-12': _settings.gap_x === 12 && _settings.grid,
              'gap-x-14': _settings.gap_x === 14 && _settings.grid,
              'gap-x-16': _settings.gap_x === 16 && _settings.grid,
              'gap-x-18': _settings.gap_x === 18 && _settings.grid,
              'gap-x-20': _settings.gap_x === 20 && _settings.grid,
              'gap-y-0': _settings.gap_y === 0 && _settings.grid,
              'gap-y-2': _settings.gap_y === 2 && _settings.grid,
              'gap-y-4': _settings.gap_y === 4 && _settings.grid,
              'gap-y-6': _settings.gap_y === 6 && _settings.grid,
              'gap-y-8': _settings.gap_y === 8 && _settings.grid,
              'gap-y-10': _settings.gap_y === 10 && _settings.grid,
              'gap-y-12': _settings.gap_y === 12 && _settings.grid,
              'gap-y-14': _settings.gap_y === 14 && _settings.grid,
              'gap-y-16': _settings.gap_y === 16 && _settings.grid,
              'gap-y-18': _settings.gap_y === 18 && _settings.grid,
              'gap-y-20': _settings.gap_y === 20 && _settings.grid,
              'divide-y divide-y-slate-400': !_settings.grid,
            }"
          >
            @for (
              item of searchString === "" &&
              items[items.length - 1].items.length > 20
                ? pager.paginator?.paginatedData
                : pager.paginator?.filteredData;
              track item.id
            ) {
              <ngx-folders-item
                [item]="item"
                [dragable]="!selectMode && _settings.header"
                [selectMode]="selectMode"
                [mode]="mode"
                [dragged]="itemDragged != null"
                [selected]="item.id === selectedItem?.id"
                [grid]="_settings.grid"
                [contextOpen]="
                  itemMenu != null &&
                  item.id === itemMenu.options.item.content.id &&
                  itemMenu.options.item.type === 'item'
                "
                (clicked)="goToItem($event)"
                (rightClicked)="rightClick($event.event, $event.content)"
                (drag)="drag($event)"
                (drop)="drop()"
              >
                <ng-template grid-folder-item-tmp let-item="item">
                  <ng-template
                    [ngTemplateOutlet]="folderItem"
                    [ngTemplateOutletContext]="{ item: item }"
                  />
                </ng-template>
              </ngx-folders-item>
            }

            @if (
              pager.paginator?.filteredData.length === 0 && searchString !== ""
            ) {
              <div
                class="flex items-center justify-center col-span-full text-gray-500"
              >
                No matching {{ _settings.display_type.item }} found.
              </div>
            }

            @if (items[items.length - 1].items.length === 0) {
              <div
                class="col-span-full flex items-center justify-center text-gray-500 mb-10"
              >
                {{
                  items.length === 1
                    ? "The root folder is empty."
                    : "This folder is empty."
                }}
              </div>
            }
          </div>
        }
      </div>
    }
    @if (!_settings.grid && _settings.listHeader != null) {
      <div class="flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  @if (!_settings.noImage) {
                    <th
                      scope="col"
                      [style.width.%]="10"
                      class="py-3 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    ></th>
                  }
                  @for (col of _settings.listHeader; track col) {
                    <th
                      scope="col"
                      class="py-3 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      [style.width.%]="
                        _settings.noImage ? 95 * col.size : 85 * col.size
                      "
                    >
                      {{ col.name }}
                    </th>
                  }
                  <th scope="col" [style.width.%]="5"></th>
                </tr>
              </thead>
              @if (items.length > 1) {
                <tbody
                  cdkDropList
                  ngx-folders-folder
                  [backFolder]="true"
                  [folder]="{
                    name: 'Back',
                    id: items[items.length - 2]?.id,
                  }"
                  [selectMode]="selectMode"
                  [dragged]="itemDragged != null"
                  [selected]="items[items.length - 2]?.id === folderOver?.id"
                  [grid]="_settings.grid"
                  [headerLength]="_settings.listHeader?.length"
                  [noImage]="_settings.noImage"
                  (clicked)="back()"
                  (exited)="exited()"
                  (enter)="enter($event)"
                >
                  <ng-template
                    *ngIf="subFolder != null"
                    list-sub-folder-tmp
                    let-folder="folder"
                    let-open="open"
                  >
                    <ng-template
                      [ngTemplateOutlet]="subFolder"
                      [ngTemplateOutletContext]="{ folder: folder, open: open }"
                    />
                  </ng-template>
                </tbody>
              }
              @if (_settings.header) {
                @for (folder of items[items.length - 1].folders; track folder) {
                  <tbody
                    cdkDropList
                    ngx-folders-folder
                    [folder]="folder"
                    [selectMode]="selectMode"
                    [dragged]="itemDragged != null"
                    [selected]="folder.id === folderOver?.id"
                    [grid]="_settings.grid"
                    [headerLength]="_settings.listHeader?.length"
                    [contextOpen]="
                      folderMenu != null &&
                      folder.id === folderMenu.options.item.content.id &&
                      folderMenu.options.item.type === 'folder'
                    "
                    [noImage]="_settings.noImage"
                    (clicked)="open($event)"
                    (drag)="drag($event)"
                    (rightClicked)="rightClick($event.event, $event.content)"
                    (drop)="drop()"
                    (exited)="exited()"
                    (enter)="enter($event)"
                  >
                    <ng-template
                      *ngIf="subFolder != null"
                      list-sub-folder-tmp
                      let-folder="folder"
                      let-open="open"
                    >
                      <ng-template
                        [ngTemplateOutlet]="subFolder"
                        [ngTemplateOutletContext]="{
                          folder: folder,
                          open: open,
                        }"
                      />
                    </ng-template>
                  </tbody>
                }
              }
              @for (
                item of !_settings.hasFolders
                  ? items[items.length - 1].items
                  : items[items.length - 1].items.length > 20
                    ? pager.paginator?.paginatedData
                    : pager.paginator?.filteredData;
                track item
              ) {
                <tbody
                  cdkDropList
                  ngx-folders-item
                  class="group"
                  [item]="item"
                  [dragable]="!selectMode && _settings.header"
                  [selectMode]="selectMode"
                  [dragged]="itemDragged != null"
                  [selected]="selectedItem?.id === item.id"
                  [grid]="_settings.grid"
                  [contextOpen]="
                    itemMenu != null &&
                    item.id === itemMenu.options.item.content.id &&
                    itemMenu.options.item.type === 'item'
                  "
                  [image]="delegate?.getItemImage(item)"
                  (clicked)="goToItem($event)"
                  (drag)="drag($event)"
                  (rightClicked)="rightClick($event.event, $event.content)"
                  (drop)="drop()"
                >
                  <ng-template list-folder-item-tmp let-item="item">
                    <ng-template
                      [ngTemplateOutlet]="folderItem"
                      [ngTemplateOutletContext]="{ item: item }"
                    />
                  </ng-template>
                </tbody>
              }
              @if (
                (items[items.length - 1].items.length === 0 ||
                  pager.paginator?.filteredData.length === 0) &&
                searchString !== ""
              ) {
                <tbody class="text-gray-500">
                  <div class="h-10 text-sm flex items-center justify-start">
                    No matches found
                  </div>
                </tbody>
              }
            </table>
          </div>
        </div>
        <!-- <div
        style="visibility: hidden; position: fixed"
        [style.left]="menuTopLeftPosition.x"
        [style.top]="menuTopLeftPosition.y"
        [matMenuTriggerFor]="rightMenu"
      ></div> -->
      </div>
    }
    <div
      class="flex items-center justify-center"
      [ngClass]="{
        invisible:
          items[items.length - 1].items.length < 20 ||
          !this._settings.hasFolders,
      }"
    >
      <confect-pager #pager [pagerInfo]="pagerInfo" />
    </div>
  </div>
}

<!-- <mat-menu #rightMenu="matMenu" class="font-normal">
<ng-template matMenuContent let-item="item">
  <ng-container *ngIf="item.type === 'item'">
    <ng-container *ngFor="let option of itemOptions">
      <button
        *ngIf="!option.hide(item)"
        mat-menu-item
        (click)="option.function(item)"
        class="space-x-2 w-52 font-sans"
        >
        <confect-icon [icon]="option.icon" iconClass="mr-2"></confect-icon
          ><span>{{ option.display }}</span>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="item.type === 'folder'">
      <ng-container *ngFor="let option of folderOptions">
        <button
          mat-menu-item
          (click)="option.function(item)"
          class="space-x-2 font-sans"
          >
          <confect-icon [icon]="option.icon" iconClass="mr-2"></confect-icon
            ><span>{{ option.display }}</span>
          </button>
        </ng-container>
      </ng-container>
    </ng-template>
  </mat-menu> -->

@if (!selectMode) {
  <ng-template #renameModal let-close="close">
    @if (selectedItem != null) {
      <div class="text-center flex flex-col items-center space-y-5">
        <h6>
          Enter {{ selectedItem.type !== "new folder" ? "new " : "" }}name
        </h6>
        <confect-input [(ngModel)]="selectedItem.content.name" />
        <div>
          <div class="flex space-x-10">
            <confect-button (click)="close(false)" type="secondary"
              >Cancel</confect-button
            >
            @if (selectedItem.type === "item") {
              <confect-button
                (click)="renameItem(selectedItem.content); close(true)"
                type="primary"
                >Save</confect-button
              >
            }
            @if (selectedItem.type === "folder") {
              <confect-button
                (click)="
                  renameFolder(
                    selectedItem.content.id,
                    selectedItem.content.name
                  );
                  close(true)
                "
                type="primary"
                >Save</confect-button
              >
            }
            @if (selectedItem.type === "new folder") {
              <confect-button
                (click)="
                  addFolder(
                    _settings.type,
                    selectedItem.content.name,
                    items[items.length - 1].id
                  );
                  close(true)
                "
                type="primary"
              >
                Create
              </confect-button>
            }
          </div>
        </div>
      </div>
    }
  </ng-template>
}

@if (!selectMode) {
  <ng-template #moveModal let-close="close">
    @if (selectedItem != null) {
      <div class="text-center flex flex-col items-center space-y-5">
        <h6>Move element to folder:</h6>
        <confect-select
          [options]="items[items.length - 1].folders"
          [searchable]="false"
          [clearable]="true"
          placeholder="Move to parent folder"
          [(ngModel)]="selectedFolder"
          class="w-80"
        />
        <div>
          <div class="flex space-x-10">
            <confect-button (click)="close(false)" type="secondary"
              >Cancel</confect-button
            >
            <confect-button
              (click)="
                moveItem(selectedItem.content.id, selectedFolder); close(true)
              "
              type="primary"
              >Move</confect-button
            >
          </div>
        </div>
      </div>
    }
  </ng-template>
}
