@if (mode === "default") {
  <div class="grid grid-cols-10 gap-4 w-full h-full">
    <div
      class="p-4 max-h-full border-r border-gray-200 overflow-y-hidden col-span-3"
    >
      <div class="max-h-full overflow-y-auto">
        <div class="flex flex-col space-y-2">
          @for (section of sections; track section.name; let si = $index) {
            <button
              (click)="
                section.special
                  ? select(si, 0)
                  : (section.collapsed = !section.collapsed)
              "
              [ngClass]="{
                'bg-gray-100': section.special && selection.section === si,
              }"
              class="hover:bg-gray-50 hover:text-gray-900 py-5 pl-2 pr-1 w-full text-xl font-semibold flex justify-between items-center rounded-md cursor-pointer text-gray-600 no-underline focus:outline-none focus:ring-2 focus:ring-primary ring-inset"
            >
              {{
                section.special
                  ? section.categories[0].name
                  : (section.name | ngxCapitalize)
              }}
              @if (!section.special) {
                <confect-icon
                  iconClass="text-gray-500 text-2xl"
                  [icon]="section.collapsed ? 'caret_right' : 'caret_down'"
                />
              }
            </button>
            @if (!section.special && !section.collapsed) {
              @for (c of section.categories; track c.name; let ci = $index) {
                <button
                  (click)="select(si, ci)"
                  [ngClass]="{
                    'bg-gray-100':
                      selection.section === si && selection.category === ci,
                  }"
                  class="hover:text-gray-900 hover:bg-gray-50 text-xl pl-8 pr-2 py-2 w-full flex flex-col justify-center rounded-md cursor-pointer text-gray-600 no-underline ring-inset focus:outline-none focus:ring-2 focus:ring-primary"
                >
                  <div class="text-left">{{ c.name | ngxCapitalize }}</div>
                  @if (c.description != null && c.description !== "") {
                    <div class="text-sm text-left text-gray-500">
                      {{ c.description }}
                    </div>
                  }
                </button>
              }
            }
          }
        </div>
      </div>
    </div>
    <div class="overflow-hidden col-span-7 p-4">
      @if (
        selection != null &&
        sections != null &&
        !sections[selection.section]?.special
      ) {
        <ng-container [ngTemplateOutlet]="templates" />
      }
      @if (
        selection != null &&
        sections != null &&
        sections[selection.section]?.special
      ) {
        <ngx-design-select-modal
          (submit)="
            openDesign(
              sections[selection.section].categories[selection.category],
              $event
            )
          "
          [selectOnClick]="true"
        />
      }
    </div>
  </div>
}

@if (mode === "radio") {
  <div class="w-full h-full">
    @if (selection != null) {
      <ng-container [ngTemplateOutlet]="templates" />
    }
  </div>
}

<ng-template #templates>
  @if (
    selection != null &&
    sections[selection.section]?.type === "brand" &&
    uniqueGuideNames.length > 1
  ) {
    <div class="flex items-center justify-center w-full mt-4">
      <confect-select
        class="w-64"
        placeholder="All guidelines"
        [(ngModel)]="currentGuide"
        (ngModelChange)="filterSections()"
        [options]="uniqueGuideNames"
        [clearable]="true"
      />
    </div>
  }

  <div class="max-h-full overflow-y-auto">
    <div class="grid grid-cols-2 gap-8 xl:grid-cols-3 3xl:grid-cols-4">
      @for (
        item of sections[selection.section]?.categories[selection.category]
          .items;
        track item
      ) {
        <img
          (click)="
            openDesign(
              sections[selection.section].categories[selection.category],
              item
            )
          "
          class="cursor-pointer rounded-lg border"
          [lazyLoad]="item.thumbnail"
        />
      }
    </div>
  </div>
</ng-template>
