import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { Creative } from "@core/api-models/designs.models";

import { CModalComponent } from "@theme/@confect/components/modal/modal.component";

@Component({
  selector: "ngx-creative-selector-v2",
  templateUrl: "./creative-selector.component.html",
  styleUrls: ["./creative-selector.component.scss"],
})
export class CreativeSelectorV2Component {
  @ViewChild("modal") modal: CModalComponent;

  @Input() selectedDesignId: number;
  selectedDesign?: Creative;

  @Output() designSelected: EventEmitter<Creative> =
    new EventEmitter<Creative>();

  selectCreative(design: Creative): void {
    this.selectedDesign = design;
    this.selectedDesignId = design?.id;
  }

  submitHandler(): void {
    if (this.selectCreative != null) {
      this.designSelected.emit(this.selectedDesign);
    }

    this.modal.hide();
  }

  show() {
    this.modal.show();
  }
}
