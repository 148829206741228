@if (grid) {
  <div
    cdkDropList
    [cdkDropListData]="{ content: item, type: 'item' }"
    (contextmenu)="
      rightClicked.emit({
        event: $event,
        content: { content: item, type: 'item' },
      })
    "
    (click)="clicked.emit(item)"
  >
    <div
      cdkDrag
      [cdkDragDisabled]="!dragable"
      (cdkDragStarted)="drag.emit({ content: item, type: 'item' })"
      (cdkDragEnded)="drop.emit()"
      class="z-[1050]"
    >
      <div *cdkDragPlaceholder></div>
      <div class="relative group">
        @if (!selectMode && !dragged) {
          <div
            class="absolute right-2 top-2 bg-white rounded shadow-md z-[100]"
            (click)="
              $event.stopPropagation();
              rightClicked.emit({
                event: $event,
                content: { content: item, type: 'item' },
              })
            "
            [ngClass]="{
              block: contextOpen,
              'hidden group-hover:block': !contextOpen,
            }"
          >
            @if (!selectMode) {
              <confect-icon
                icon="more_vertical"
                class="text-2xl hover:text-slate-400 cursor-pointer"
              />
            }
          </div>
        }
        @if (mode === "radio" && selectMode) {
          <div class="absolute right-2 top-2 z-[100]">
            <div
              class="items-center"
              [ngClass]="{
                'group-hover:block hidden': !selected,
                block: selected,
              }"
            >
              <confect-checkbox
                (click)="clicked.emit(item)"
                [value]="selected"
                [disable]="selected"
              />
            </div>
          </div>
        }
        <div
          [ngClass]="{ 'hover:opacity-70': mode === 'submit' && selectMode }"
        >
          <ng-template
            [ngTemplateOutlet]="folderItemGrid"
            [ngTemplateOutletContext]="{
              item: item,
            }"
          />
        </div>
      </div>
    </div>
  </div>
}
@if (!grid) {
  <tr
    cdkDrag
    [cdkDragDisabled]="!dragable"
    [cdkDragData]="{ content: item, type: 'item' }"
    (cdkDragStarted)="drag.emit({ content: item, type: 'item' })"
    (cdkDragEnded)="drop.emit()"
    (contextmenu)="
      rightClicked.emit({
        event: $event,
        content: { content: item, type: 'item' },
      })
    "
    (click)="clicked.emit(item)"
    class="bg-white group cursor-pointer z-[1050]"
  >
    <div *cdkDragPreview class="z-[1050]">
      @if (image != null) {
        <img [src]="image" class="object-contain object-center w-32" />
      }
      @if (image == null) {
        <div class="w-32 h-32 bg-gray-300"></div>
      }
    </div>
    <div *cdkDragPlaceholder class="h-20 bg-white"></div>
    <ng-template
      [ngTemplateOutlet]="folderItemList"
      [ngTemplateOutletContext]="{
        item: item,
      }"
    />
    <td>
      @if (!selectMode && !dragged) {
        <div
          class="rounded shadow-md w-8 text-center"
          (click)="
            $event.stopPropagation();
            rightClicked.emit({
              event: $event,
              content: { content: item, type: 'item' },
            })
          "
          [ngClass]="{
            block: contextOpen,
            'hidden group-hover:block': !contextOpen,
          }"
        >
          <confect-icon
            icon="more_vertical"
            class="text-2xl hover:text-slate-400 cursor-pointer"
          />
        </div>
      }
      @if (selectMode && selected) {
        <div>
          <div class="flex items-center">
            <confect-checkbox [value]="true" [disable]="true" />
          </div>
        </div>
      }
      @if (selectMode && !selected) {
        <div>
          <div class="group-hover:block hidden items-center">
            <confect-checkbox [value]="false" [disable]="true" />
          </div>
        </div>
      }
    </td>
  </tr>
}
