<ngx-folders
  #folders
  [delegate]="this"
  [settings]="settings"
  [selectMode]="_radioMode"
  [mode]="mode"
  [selectedItem]="selectedDesignElement"
  (itemSelected)="elementSelected($event)"
>
  <ng-template folder-item-tmp let-item="item">
    <div class="flex flex-col aspect-square">
      <div
        class="flex flex-col items-center justify-center h-full w-full p-4 rounded-lg bg-slate-100 shadow-sm text-center"
        [ngClass]="{
          'cursor-default': !(_radioMode && mode === 'submit'),
          'cursor-pointer': _radioMode && mode === 'submit',
        }"
      >
        @if (item.thumbnail != null) {
          <div class="w-full h-full">
            <img
              class="w-full h-full aspect-square rounded object-contain object-center"
              [src]="item.thumbnail"
            />
          </div>
        }
        @if (item.thumbnail == null) {
          <div class="bg-gray-300 rounded"></div>
        }
      </div>

      <div>
        <h3 class="text-xs font-medium text-gray-900 pl-1 pt-2 pr-4">
          {{ item.name }}
        </h3>
      </div>
    </div>
  </ng-template>
</ngx-folders>
@if (folders.isEmpty && !folders.loading) {
  <div>
    <ngx-empty-state [size]="empty_size" [info]="empty_state" />
  </div>
}
