<ngx-folders
  #folders
  [delegate]="this"
  [settings]="settings"
  [selectMode]="_radioMode"
  [mode]="mode"
  [extras]="{ hideOptions: ['duplicate'] }"
  (itemSelected)="mediaSelect.emit($event)"
>
  @if (type === "visual"; as item) {
    <ng-template folder-item-tmp let-item="item">
      <div class="flex flex-col aspect-square group">
        <div
          class="flex flex-col items-center justify-center h-full w-full p-4 rounded-lg bg-slate-100 shadow-sm text-center"
          [ngClass]="{
            'cursor-pointer group-hover:bg-slate-200':
              !_radioMode && mode === 'submit',
            'cursor-default': _radioMode || mode !== 'submit',
          }"
        >
          @if (item?.variants?.default?.url_thumb != null) {
            <div class="w-full h-full">
              @if (item.type === "image") {
                <img
                  class="w-full h-full aspect-square rounded object-contain object-center"
                  [lazyLoad]="item.variants.default.url_thumb"
                />
              }
              @if (item.type === "video") {
                <video
                  autoplay
                  muted
                  loop
                  class="w-full h-full aspect-square rounded object-contain object-center"
                  [src]="item.variants.default.url_thumb"
                ></video>
              }
            </div>
          }
          @if (item?.variants?.default?.url_thumb == null) {
            <div class="bg-gray-300 rounded"></div>
          }
        </div>
        <div>
          <h3 class="text-xs font-medium text-gray-900 pl-1 pt-2 pr-4">
            {{ item.name | truncate: 25 }}
          </h3>
        </div>
      </div>
    </ng-template>
  }
  @if (type === "font"; as item) {
    <ng-template folder-item-tmp let-item="item">
      <td
        class="text-sm font-medium text-gray-700 h-16"
        [ngClass]="{
          'cursor-pointer group-hover:text-primary':
            !_radioMode && mode === 'submit',
          'cursor-default': _radioMode || mode !== 'submit',
        }"
      >
        {{ item.name }}
      </td>
      <td
        class="text-gray-500 overflow-ellipsis overflow-hidden text-xl"
        [ngClass]="{
          'cursor-pointer group-hover:text-primary':
            !_radioMode && mode === 'submit',
          'cursor-default': _radioMode || mode !== 'submit',
        }"
      >
        @if (loadedFonts[item.id]) {
          <div [ngClass]="['font' + item.id]">AaBbCcDdEeFfGg</div>
        }
      </td>
    </ng-template>
  }
</ngx-folders>
<div>
  <confect-loading [loading]="folders.loading" />
</div>
@if (folders.isEmpty && !folders.loading) {
  <div>
    <ngx-empty-state [size]="empty_size" [info]="empty_state" />
  </div>
}
