<div class="w-[1000px]">
  <mat-accordion>
    @for (key of _keys; track key) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ key }}</mat-panel-title>
        </mat-expansion-panel-header>
        <ngx-designs-overview-import-table
          [creatives]="_creatives[key]"
          (creativeSelected)="rowClicked($event)"
        />
      </mat-expansion-panel>
    }
  </mat-accordion>
</div>
